@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap);
@import url(https://fonts.googleapis.com/earlyaccess/nats.css);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap);
:root{--background-color: #faeee7;--headline-color: #33272a;--paragraph-color: #594a4e;--button-color: #ff8ba7;--button-text-color: #ff8ba7;--tertiary-color: #c3f0ca}body{margin:0;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#faeee7;background-color:var(--background-color);background:url(/static/media/Landing.4b70871d.svg) no-repeat scroll;background-size:cover}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}*{box-sizing:border-box}
.App_App__1xjOI{text-align:center}.App_App__1xjOI .App_App-header__1yqHs{min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:var(--heading-color)}.App_App__1xjOI .App_App-header__1yqHs a{color:var(--heading-color)}
.Header_header__1v0yI{display:flex;justify-content:space-around;font-family:Raleway,sans-serif;font-size:14px;align-items:center;text-align:center;letter-spacing:.03em}.Header_header__1v0yI .Header_logo__3T0lc{display:flex;padding:20px;color:var(--headline-color);font-family:NATS,sans-serif;cursor:pointer}.Header_header__1v0yI .Header_logo__3T0lc span{margin:0;margin-left:6px;font-size:2rem;font-weight:bold;letter-spacing:2px}.Header_header__1v0yI .Header_navbar__359Kr{display:flex;align-items:center;text-align:center}.Header_header__1v0yI .Header_navbar__359Kr p{cursor:pointer}.Header_header__1v0yI .Header_navbar__359Kr .Header_signin__2nnEA{border:1px solid var(--headline-color);box-sizing:border-box;padding:10px 20px}.Header_header__1v0yI .Header_navbar__359Kr .Header_signin__2nnEA:hover{background-color:var(--headline-color);color:#faeee7}.Header_header__1v0yI .Header_navbar__359Kr .Header_register__9t4qb{margin:20px;font-size:1.2em}@media screen and (min-width: 0px)and (max-width: 500px){.Header_Header__1CTzX{display:block}.Header_navbar__359Kr{display:block;text-align:left}}
.Footer_footer__7dIj9{position:relative;left:0;bottom:0;width:100%;font-size:1.2rem;text-align:center;font-family:Raleway,sans-serif;color:var(--headline-color)}.Footer_footer__7dIj9 a{color:inherit;text-decoration:none}.Footer_footer__7dIj9 a:hover{text-decoration:underline}
.Hero_hero__14Fne{display:flex;justify-content:space-around;align-items:center}.Hero_hero__14Fne .Hero_booksContainer__cclbB{height:auto;width:50%;min-width:200px}.Hero_hero__14Fne .Hero_text__HmkbC{font-style:normal;font-weight:bold;font-size:clamp(2em, 4.5vw, 5em);line-height:1em;display:block;text-align:left;align-items:center;letter-spacing:.03em;color:var(--headline-color);margin-left:2em}.Hero_hero__14Fne .Hero_text__HmkbC .Hero_tagline__3Rhee{display:flex;flex-wrap:wrap;font-family:"Quicksand",sans-serif;font-style:normal;font-weight:400;font-size:.7em}@media screen and (max-width: 500px){.Hero_hero__14Fne{display:block;margin-left:auto;margin-right:auto}.Hero_booksContainer__cclbB{min-width:200px;margin-left:3.5em}}
.Forms_form__eA5b3{width:50%;margin:auto;position:relative;padding:30px;align-items:center;justify-content:center}.Forms_form__eA5b3 .Forms_formheading__8o3ZY{font-family:Noto Sans,sans-serif;font-weight:600;font-size:1.5em;position:relative}.Forms_form__eA5b3 .Forms_formfield__3Df-K{width:50%;min-width:280px;padding:15px;border:1px solid var(--headline-color);height:100%;background-color:transparent;resize:vertical;outline:none;color:var(--headline-color);display:block;margin:auto;margin-top:20px}.Forms_form__eA5b3 .Forms_submit__2R1oW{border:1px solid var(--headline-color);box-sizing:border-box;padding:10px 20px;background-color:var(--headline-color);color:var(--background-color);margin-top:20px;font-family:Raleway,sans-serif;font-weight:600;font-size:1em;letter-spacing:.05em;cursor:pointer}.Forms_form__eA5b3 .Forms_submit__2R1oW:hover{box-shadow:0 0px 0px var(--headline-color),0 1px 1px var(--headline-color),0 2px 2px var(--headline-color),0 3px 3px var(--headline-color),0 4px 4px var(--headline-color)}@media screen and (max-width: 600px){.Forms_form__eA5b3{display:block;width:100%}.Forms_form__eA5b3 .Forms_formfield__3Df-K{width:70%}}
.Homepage_Forms__12Tmz{display:flex;position:relative;flex-wrap:wrap}
