.header {
  display: flex;
  justify-content: space-around;
  font-family: Raleway, sans-serif;
  font-size: 14px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;

  .logo {
    display: flex;
    padding: 20px;
    color: var(--headline-color);
    font-family: NATS, sans-serif;
    cursor: pointer;

    span {
      margin: 0;
      margin-left: 6px;
      font-size: 2rem;
      font-weight: bold;
      letter-spacing: 2px;
    }
  }

  .navbar {
    display: flex;
    align-items: center;
    text-align: center;

    p {
      cursor: pointer;
    }

    .signin {
      border: 1px solid var(--headline-color);
      box-sizing: border-box;
      padding: 10px 20px;

      &:hover {
        background-color: var(--headline-color);
        color: #faeee7;
      }
    }

    .register {
      margin: 20px;
      font-size: 1.2em;
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 500px) {
  .Header {
    display: block;
  }

  .navbar {
    display: block;
    text-align: left;
  }
}
