.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
  font-family: Raleway, sans-serif;
  color: var(--headline-color);

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
