.App {
  text-align: center;

  .App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--heading-color);

    a {
      color: var(--heading-color);
    }
  }
}
