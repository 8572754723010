.hero {
  display: flex;
  justify-content: space-around;
  align-items: center;

  .booksContainer {
    height: auto;
    width: 50%;
    min-width: 200px;
  }

  .text {
    font-style: normal;
    font-weight: bold;
    font-size: clamp(2em, 4.5vw, 5em);
    line-height: 1em;
    display: block;
    text-align: left;
    align-items: center;
    letter-spacing: 0.03em;
    color: var(--headline-color);
    margin-left: 2em;

    .tagline {
      display: flex;
      flex-wrap: wrap;
      font-family: 'Quicksand', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.7em;
    }
  }
}
@media screen and (max-width: 500px) {
  .hero {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .booksContainer {
    min-width: 200px;
    margin-left: 3.5em;
  }
}
